import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2 p-0" }
const _hoisted_2 = { class: "col-md-4 mb-4" }
const _hoisted_3 = { class: "row gy-5 g-xl-8" }
const _hoisted_4 = { class: "col-xxl-12 md-12" }
const _hoisted_5 = { class: "col-xxl-12 md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_TotalCount = _resolveComponent("TotalCount")!
  const _component_TotalTable = _resolveComponent("TotalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_select, {
          class: "form-select-solid",
          placeholder: "Select Tranche",
          modelValue: _ctx.data.trance,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.trance) = $event)),
          filterable: "",
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              label: "Tranche-1",
              value: "1"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Tranche-1")
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              label: "Additional Tranche-1",
              value: "2"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Additional Tranche-1")
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              label: "Tranche-3",
              value: "3"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Tranche-2")
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              label: "Additional Tranche-2",
              value: "2"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Additional Tranche-2")
              ]),
              _: 1
            }),
            _createVNode(_component_el_option, {
              label: "Tranche-3",
              value: "4"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Tranche-3")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TotalCount, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "success",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_TotalTable, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-10" })
      ])
    ])
  ], 64))
}